import React from 'react';
import {Field} from 'formik';

import {standardDefaultPropsForFields, standardPropTypesForFields} from '../constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {renderRecaptchaField} from './renderFields';

/** @component */
const Captcha = ({name, validate}) => (
	<React.Fragment>
		<Field name={name} validate={validate}>
			{renderRecaptchaField}
		</Field>
		<ErrorMessage name={name} />
	</React.Fragment>
);

Captcha.propTypes = standardPropTypesForFields;
Captcha.defaultProps = standardDefaultPropsForFields;

export default Captcha;
