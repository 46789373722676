import styled from 'styled-components';
import {media} from '../../../styles/utils';
import {colors} from '../../../styles/variables';
import {HeadlineStyledH3, HeadlineStyledH2} from '../Headline/styles';
import {Icon} from '../Icon/Icon';

export const AccordionContainer = styled.div``;

export const AccordionList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: #fff;
	max-width: 100%;
	${(props) =>
		props.variant === 'version_2'
			? `border-top: 2px solid ${colors.carrot}; border-bottom: 2px solid ${colors.carrot};`
			: ''};

	overflow: hidden;
`;
export const AccordionListItem = styled.li`
	& + & {
		${(props) => (props.variant === 'version_2' ? `border-top: 2px solid ${colors.carrot};` : '')};
	}
`;

export const AccordionItemLine = styled.div`
	display: block;
	${(props) =>
		props.variant === 'version_2' ? `padding: 0.8rem 1.2rem;` : 'padding: 0 1.2rem; margin-bottom: 1rem;'};

	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	z-index: 2;
	position: relative;
`;

export const AccordionItemTitle = styled(HeadlineStyledH3)`
	margin: 0 0.5em !important;
	padding: 0 !important;
	color: ${colors.carrot};

	&::after {
		display: none;
	}
`;

export const AccordionItemTitle2 = styled(HeadlineStyledH2)`
	font-size: 1.2em;
	margin: 0.5em !important;
	padding: 0 !important;

	${media.lg` 		font-size: 1.5em; 	`};
`;

export const AccordionItemIcon = styled(Icon)`
	transition: transform 0.3s ease-in-out;
	opacity: 0.6;
	transform: rotate(270deg);

	color: ${colors.carrot};

	&.open {
		transform: rotate(90deg);
	}
`;

export const AccordionItemInner = styled.div`
	max-height: 0;
	overflow: hidden;
	text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
	transition-duration: 0.5s;
	transition-property: max-height;
	z-index: 1;
	position: relative;

	&.open {
		max-height: 1000rem;
		transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
		transition-duration: 0.5s;
		transition-property: max-height;
	}
`;

export const AccordionItemContent = styled.div`
	opacity: 0;
	transform: translateY(-1rem);
	transition-timing-function: linear, ease;
	transition-duration: 0.1s;
	transition-property: opacity, transform;
	transition-delay: 0.5s;
	padding: 1.2rem;
	background: #efefef;

	&.open {
		opacity: 1;
		transform: translateY(0);
		transition-delay: 0.2s;
		transition-timing-function: ease-in-out;
		transition-duration: 0.2s;
		transition-property: opacity, transform;
	}
`;

export const AccordionItemStyled = styled.div`
	margin-bottom: 1rem;
`;
