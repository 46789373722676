import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {FrameForErrorState} from './styles';

import {hasError} from '../formUtils';

// constants
const SITE_KEY = '6LeHnRwTAAAAAJmpqzxzMHCbAZKEUrck7fZeOmfr';

export const renderRecaptchaField = formikProps => {
	const {
		field: {name},
		form: {setFieldTouched, setFieldValue}
	} = formikProps;

	return (
		<FrameForErrorState hasError={hasError(formikProps)}>
			<ReCAPTCHA
				onChange={val => {
					setFieldValue(name, val); // because formikProps.field.onChange() doesn't work here
					setFieldTouched(name); // always
				}}
				sitekey={SITE_KEY}
				hl='de'
			/>
		</FrameForErrorState>
	);
};
