import styled from 'styled-components';
import {alertColors, sizes} from '../../../../styles/variables';
import {withUnit} from '../../../../styles/utils';

export const FrameForErrorState = styled.div`
	margin-top: ${withUnit(sizes.distance.base)};
	display: inline-block;
	border-width: 2px;
	border-style: solid;
	border-color: ${props => (props.hasError ? alertColors.error.full : 'rgba(0, 0, 0, 0)')};
`;
