import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	AccordionItemStyled,
	AccordionContainer,
	AccordionList,
	AccordionListItem,
	AccordionItemLine,
	AccordionItemTitle,
	AccordionItemTitle2,
	AccordionItemIcon,
	AccordionItemInner,
	AccordionItemContent,
} from './styles';

const Accordion = ({data, style}) => {
	return (
		<AccordionContainer>
			<AccordionList variant={style}>
				{data.map((el, key) => {
					return (
						<AccordionListItem {...{key}} variant={style}>
							<AccordionItem {...el} variant={style} />
						</AccordionListItem>
					);
				})}
			</AccordionList>
		</AccordionContainer>
	);
};

const AccordionItem = ({title, content, variant}) => {
	const [opened, setOpen] = useState(false);
	return (
		<AccordionItemStyled>
			<AccordionItemLine onClick={() => setOpen(!opened)} variant={variant}>
				{variant === 'version_1' ? (
					<AccordionItemTitle>{title}</AccordionItemTitle>
				) : (
					<AccordionItemTitle2>{title}</AccordionItemTitle2>
				)}
				<AccordionItemIcon className={opened ? 'open' : ''} name="chevron_left" />
			</AccordionItemLine>
			<AccordionItemInner className={opened ? 'open' : ''}>
				<AccordionItemContent className={opened ? 'open' : ''}>{content}</AccordionItemContent>
			</AccordionItemInner>
		</AccordionItemStyled>
	);
};

Accordion.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
};

Accordion.defaultProps = {
	data: null,
};

AccordionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.node,
};

AccordionItem.defaultProps = {
	title: undefined,
	content: undefined,
};

export default Accordion;
